var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "section",
      { attrs: { id: "knowledge-base-search" } },
      [
        _c(
          "b-card",
          {
            staticClass: "knowledge-base-bg text-center",
            style: {
              backgroundImage:
                "url(" + require("@/assets/images/banner/banner.png") + ")"
            },
            attrs: { "no-body": "" }
          },
          [
            _c(
              "b-card-body",
              { staticClass: "card-body" },
              [
                _c("h2", { staticClass: "text-primary" }, [
                  _vm._v(" Dedicated Source Used on Website ")
                ]),
                _c("b-card-text", { staticClass: "mb-2" }, [
                  _c("span", [_vm._v("Popular searches: ")]),
                  _c("span", { staticClass: "font-weight-bolder" }, [
                    _vm._v("Sales automation, Email marketing")
                  ])
                ]),
                _c(
                  "b-form",
                  { staticClass: "kb-search-input" },
                  [
                    _c(
                      "b-input-group",
                      { staticClass: "input-group-merge" },
                      [
                        _c(
                          "b-input-group-prepend",
                          { attrs: { "is-text": "" } },
                          [
                            _c("feather-icon", {
                              attrs: { icon: "SearchIcon" }
                            })
                          ],
                          1
                        ),
                        _c("b-form-input", {
                          attrs: {
                            id: "searchbar",
                            placeholder: "Ask a question...."
                          },
                          model: {
                            value: _vm.knowledgeBaseSearchQuery,
                            callback: function($$v) {
                              _vm.knowledgeBaseSearchQuery = $$v
                            },
                            expression: "knowledgeBaseSearchQuery"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    ),
    _c(
      "section",
      { attrs: { id: "knowledge-base-content" } },
      [
        _c(
          "b-row",
          { staticClass: "kb-search-content-info match-height" },
          [
            _vm._l(_vm.filteredKB, function(item) {
              return _c(
                "b-col",
                {
                  key: item.id,
                  staticClass: "kb-search-content",
                  attrs: { md: "4", sm: "6" }
                },
                [
                  _c(
                    "b-card",
                    {
                      staticClass: "text-center cursor-pointer",
                      attrs: {
                        "img-src": item.img,
                        "img-alt": item.img.slice(5),
                        "img-top": ""
                      },
                      on: {
                        click: function($event) {
                          return _vm.$router.push({
                            name: "pages-knowledge-base-category",
                            params: { category: item.category }
                          })
                        }
                      }
                    },
                    [
                      _c("h4", [_vm._v(_vm._s(item.title))]),
                      _c("b-card-text", { staticClass: "mt-1" }, [
                        _vm._v(" " + _vm._s(item.desc) + " ")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            }),
            _c(
              "b-col",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.filteredKB.length,
                    expression: "!filteredKB.length"
                  }
                ],
                staticClass: "text-center",
                attrs: { cols: "12" }
              },
              [
                _c("h4", { staticClass: "mt-4" }, [
                  _vm._v(" Search result not found!! ")
                ])
              ]
            )
          ],
          2
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }